import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './Home';
import About from './About';
import Footer from './Footer'; // ✅ Only one import
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        {/* Navigation */}
        <header className="App-header">
          <div className="App-logo-container">
            <Link to="/">
              <img
                src={`${process.env.PUBLIC_URL}/alltheway.svg`}
                alt="All The Way Logo"
                className="App-logo"
              />
            </Link>
          </div>
          <nav className="App-nav-container">
            <Link to="/" className="App-link">Home</Link>
            <Link to="/about" className="App-link">About</Link>
          </nav>
        </header>

        {/* Main Content */}
        <main className="App-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
          </Routes>
        </main>

        {/* Footer */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
